.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    color: black !important;
  }

  .modal-content table th,
  .modal-content table td {
    color: black !important;
  }
  
  .image-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .preview-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .button-group {
    margin-top: 20px;
  }
  
  .upload-status {
    margin-top: 10px;
    color: #555;
  }
  