/* Cobalt Blue #0047ab
Orange #ffa500 */
*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body{
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    max-width: 100vw;
    box-sizing: border-box;
  }
  body{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #fff;
  }
  
  .main{
    display: flex;
    height: 100vh;
  }
  
  /* AppHeader */
.alert-primary {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 13%;
  z-index: 1000; /* Ensure it stays above other elements */
  background-color: #0047ab;
  color: #fff;
  transition: width 0.3s ease-in-out;
}

.alert-primary.with-sidebar {
  width: calc(100% - 260px); /* Adjust width when the sidebar is open */
  margin-left: 260px; /* Shift to the right to make space for the sidebar */
}

/* Main content adjustment */
.tenant-main {
  margin-top: 80px; /* Leave space for the fixed header */
  display: flex;
  height: calc(100vh - 80px); /* Account for header height */
}

/* Sidebar */
.sidebar {
  width: 260px;
  flex-shrink: 0;
  background-color: #0047ab;
  height: 100%;
  overflow: auto;
  transition: transform 0.3s ease-in-out;
}

.sidebar.closed {
  transform: translateX(-100%);
}

.toggle-sidebar-btn {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1100; /* Ensure above sidebar */
  background: #ffa500;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}

.container {
  flex-grow: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: margin-left 0.3s ease-in-out;
}

.table-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.container.with-sidebar {
  margin-left: 260px;
}

  /* .container{
    flex-grow: 1;
    padding: 2em;
     background-image: radial-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .8)), url('../public/banner.png'); 
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  } */
  .title{
    font-size: 3em;
  }
  .info{
    font-size: 1.1em;
    letter-spacing: 1px;
    line-height: 1.5;
    margin: 1.5em;
    color: rgb(224, 224, 224);
  }
  .btn{
    margin: 0 auto;
    border: none;
    outline: none;
    padding: .75em 1em;
    font-size: 1em;
    letter-spacing: 1px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .4);
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: rgb(134, 49, 0);
    color: #fff;
  }
  
  /* .sidebar{
    width: 260px;
    flex-shrink: 0;
    background-color: #0047ab;
    height: 100%;
    overflow: auto;
  } */
  
  
  .sidebar-item{
    padding: .75em 1em;
    display: block;
    transition: background-color .15s;
    border-radius: 5px;
  }
  .sidebar-item:hover{
    background-color: rgba(255, 255, 255, .1);
  }
  
  .sidebar-title{
    display: flex;
    font-size: 1.2em;
    justify-content: space-between;
  }
  .sidebar-title span i{
    display: inline-block;
    width: 1.5em;
  }
  .sidebar-title .toggle-btn{
    cursor: pointer;
    transition: transform .3s;
  }
  .sidebar-item.open > .sidebar-title .toggle-btn{
    transform: rotate(180deg);
  }
  .sidebar-content{
    padding-top: .25em;
    height: 0;
    overflow: hidden;
  }
  .sidebar-item.open > .sidebar-content{
    height: auto;
  }
  
  .sidebar-item.plain{
    color: #fff;
    text-decoration: none;
  }
  .sidebar-item.plain:hover{
    text-decoration: underline;
  }
  .sidebar-item.plain i{
    display: inline-block;
    width: 1.7em;
  }
  
  .calendar {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #007bff;
    color: #fff;
    font-weight: bold;
}

.days,
.body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.col {
    padding: 10px;
    text-align: center;
}

.cell {
    padding: 15px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.cell:hover {
    background: #f0f0f0;
}

.cell.selected {
    background: #007bff;
    color: #fff;
    border-radius: 50%;
}

.cell.disabled {
    color: #ccc;
    cursor: not-allowed;
}

.btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.form-group label {
  flex: 1; /* Takes up available space on the left */
  text-align: left;
  margin-left: 3rem; /* Add left margin */
  padding-right: 1rem; /* Adds space between label and input */
}

.form-group input {
  flex: 2; /* Takes up more space on the right */
  margin-right: 3rem; /* Add right margin */
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
  
.tenant-container{
  flex-grow: 1;
  padding: 2em;
  /* background-image: radial-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .8)), url('../public/banner.png'); */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

/* .member-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 16px;
} */

.member-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items */
    gap: 5px; /* Space between cards */
    padding: 10px;
}

.member-list {
  /* width: 100%;
  max-width: 100vw; 
  margin: 0 auto; 
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 1px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjusts to screen size */
  gap: 0.01in; 
  padding: 5px;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

/* .member-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 100px;
  background-color: #f9f9f9;
} */

.member-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 250%; /* Ensure the card scales with its container */
  max-width: 300px; /* Optional: Limit card size for aesthetics */
  overflow: hidden;
}

.event-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

/* .sidebar { width: 250px; } */
.card { 
  padding: 5px; 
  border: 1px solid #ddd; 
  width: 170%;
}
/* .member-list { display: flex; flex-wrap: wrap; gap: 10px; } */

.sidebar {
  width: 250px;
  /* background: #2c3e50; */
  background: #0047ab;
  color: white;
  transition: width 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.sidebar.collapsed {
  width: 80px;
}

.toggle-button {
  background: #1abc9c;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
  border-radius: 5px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.menu-title {
  display: flex;
  align-items: center;
}

.menu-title i {
  font-size: 18px;
  margin-right: 10px;
}

.submenu-list {
  list-style: none;
  padding: 5px 15px;
  margin: 0;
}

.submenu-item {
  padding: 5px 0;
}

.submenu-item a {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
}

.submenu-item a i {
  font-size: 16px;
  margin-right: 10px;
}

.submenu-item a:hover {
  color: #1abc9c;
}

.calendar {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.days {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
}

.body {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.cell {
  flex: 1;
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.cell .number {
  font-size: 16px;
}

.cell:hover {
  background-color: #f0f0f0;
}

.sidebar {
  width: 250px;
  transition: width 0.3s;
}

.sidebar.collapsed {
  width: 50px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  padding: 10px;
}

.menu-title {
  display: flex;
  align-items: center;
}

.submenu-list {
  list-style: none;
  padding-left: 20px;
  margin: 0;
}

.submenu-item {
  padding: 5px 0;
}

.submenu-toggle-icon {
  cursor: pointer;
}

.camera-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camera-controls {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between buttons */
}

.camera-controls button {
  padding: 10px 20px;
  font-size: 16px;
  background: white;
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.camera-controls button:hover {
  background: #ccc;
}

/* .camera-component {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.camera-controls {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
} */

/* .container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
} */

.icon-div {
  margin-top: 20px; /* Space above */
  margin-bottom: 10px; /* Space below */
  padding: 10px; /* Optional: Inner spacing inside the div */
}

/* .dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 15%; /* Adjust for header height 
  height: calc(100vh - 15%);
  overflow-y: auto;
} */

.calendar-container {
  display: flex;
  flex-direction: column;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 columns for 7 days */
  gap: 10px;
  width: 100%;
  margin-top: 1rem;
}

.calendar-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
}

.date {
  font-size: 14px;
  font-weight: bold;
}

.calendar-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.events {
  margin-top: 10px;
}

.event-title {
  font-size: 12px;
  color: #007bff;
  margin: 5px 0;
  text-align: center;
  border-radius: 3px;
  background-color: #e9f4ff;
  padding: 3px;
}

/* new stuff */

.owner-add-container {
  max-width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
}

.card-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333; /* Ensure text color is visible */
    word-wrap: break-word; /* Allow text to wrap if it overflows */
}

.card-body {
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 5px;
    display: flex;
    flex-direction: column; /* Stack labels on top of inputs */
    align-items: stretch;
}

.form-group label {
  /* margin-bottom: 5px; */
  /* display: block; */
  font-size: 0.9rem;
  margin-bottom: 1px; /* Add space between label and input */
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.btn:hover {
  background-color: #0056b3;
}

.status-message {
  margin-top: 15px;
  color: red;
  font-size: 0.9rem;
  text-align: center;
}

/* Ensure inputs fit on smaller screens */
@media (max-width: 768px) {
  .card {
      padding: 15px;
  }

  .card-title {
    font-size: 1.2rem; /* Slightly reduce font size */
    margin-bottom: 15px; /* Adjust spacing for smaller screens */
}

  .form-group input {
      font-size: 0.9rem;
      padding: 8px;
  }

  .btn {
      font-size: 0.9rem;
  }
}

@media (max-height: 20px) {
  .card {
      padding: 15px;
  }

  .card-title {
    font-size: 1.2rem; /* Slightly reduce font size */
    margin-bottom: 15px; /* Adjust spacing for smaller screens */
}

  .form-group input {
      font-size: 0.9rem;
      padding: 8px;
  }

  .btn {
      font-size: 0.9rem;
  }
}

/* Ensure the main content is pushed below the AppHeader */
.main-content {
  margin-top: 350px; /* Adjust this value to match the height of the header */
  padding: 10px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 columns for 7 days */
  gap: 10px;
  width: 100%;
  text-align: center;
}

.calendar-day {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #0a0808;
  font-size: 14px;
  font-weight: bold;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  gap: 10px;
  max-width: 100%;
  margin: 20px 0;
}

.event-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.task-list-container {
  max-height: 10rem; /* Adjust this value as needed */
  overflow-y: auto;
  flex-shrink: 0; /* Prevent the container from growing */
}

.task-card {
  font-size: 0.875rem; /* Smaller text */
  padding: 0.5rem;     /* Smaller padding */
  margin-bottom: 0.5rem;
}

/* Center the container and give it a reasonable max-width */
.dashboard-container {
  margin: 0 auto;          /* Center horizontally */
  margin-top: 5px;        /* Adjust top margin as needed */
  max-width: 800px;        /* Or any width you prefer */
  padding: 1rem;
  text-align: center;      /* Centers the <h2> etc. */
}

/* Make sure the table itself is centered and does not shift */
.day-table {
  margin: 0 auto;          /* Center the table */
  border-collapse: collapse;
  width: 100%;             /* Let it fill container’s width if you like */
}

/* Common cell styling */
.day-cell {
  padding: 10px;
  vertical-align: middle;  /* Keep content centered vertically */
}

/* The day-card is the clickable box */
.day-card {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: #0047ab;
  /* You can add a transition or hover effect if you like */
  transition: background-color 0.3s;
}

.day-card:hover {
  background-color: #f0f0f0;
}

/* The current day (top row) card: larger height */
.current-day {
  height: 30vh;            /* Approx top 1/3 of screen */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0047ab;
}

/* The remaining days (bottom rows) all get the same size */
.remaining-day {
  height: 20vh;            /* Make all three bottom rows match */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
