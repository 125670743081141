
.app-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    height: 12%;
    background-color: #0047ab;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 1000;
    overflow-x: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.app-header.with-sidebar {
    margin-left: 260px; /* Adjust for sidebar width */
    width: calc(100% - 260px);
    transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
}

/* Header Content */
.header-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* Stacked layout by default */
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

.header-content {
    display: flex;
    flex-direction: column; /* Stack items for smaller screens */
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.brand-title {
    font-size: 2rem;
    font-weight: bold;
    padding: 0.1rem;
}

/* Search Form */
.search-form {
    width: 100%;
    max-width: 400px;
    margin-top: 10px; 
    margin: 0 1rem;
    padding: 0.5rem;
}

.search-input {
    width: 80%;
    max-width: 400px;
    padding: 8px;
    border: none;
    border-radius: 4px;
}

.logo {
    height: 40px;
    object-fit: contain;
}

@media (max-width: 480px) {
    .app-header {
        height: 110px;
        flex-direction: column; /* Stack items on small screens */
        align-items: center;
    }

    .main-content {
        margin-top: 80px;
        padding: 5px;
    }

    .search-input {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .app-header {
        height: 130px; /* Slightly increase height for better touch usability */
    }

    .main-content {
        margin-top: 70px;
        padding: 5px;
    }

    .search-input {
        width: 90%;
        margin: 0 auto;
    }

    .brand-title {
        margin: 1rem auto;
        text-align: center;
    }

    .logo-container {
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
    }
}


/* Example from your styles */
.alert-primary {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px; /* Ensure this matches the margin-top in main-content */
    z-index: 1000;
    background-color: #0047ab;
    color: #fff;
  }